import { PartyType } from './parties';

export enum BusinessType {
  OWNER = 'OWNER',
  BUILDER = 'BUILDER',
  SUPPLIER = 'SUPPLIER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  SUPERINTENDENT = 'SUPERINTENDENT',
}

export enum RoleTypeInContract {
  OWNER = 'OWNER',
  BUILDER = 'BUILDER',
  SUPPLIER = 'SUPPLIER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  CONTRACTOR = 'CONTRACTOR',
  SUPERINTENDENT = 'SUPERINTENDENT',
}

export enum BusinessStructure {
  COMPANY = 'COMPANY',
  SOLE_TRADER = 'SOLE_TRADER',
  PARTNERSHIP = 'PARTNERSHIP',
  TRUST = 'TRUST',
  OTHER = 'OTHER',
}

export enum TrustType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export enum BusinessRoleType {
  PARTNERSHIP = 'PARTNERSHIP',
  SOLE_TRADER = 'SOLE_TRADER',
  EMPLOYEE = 'EMPLOYEE',
}

export enum CompanyRoleType {
  SOLE_DIRECTOR = 'SOLE_DIRECTOR',
  DIRECTOR = 'DIRECTOR',
  SECRETARY = 'SECRETARY',
  EMPLOYEE = 'EMPLOYEE',
}

export enum InviteRoleType {
  BUILDER = 'BUILDER',
  OWNER = 'OWNER',
  DIRECTOR = 'DIRECTOR',
  TRUST = 'TRUST',
}

export enum TrustRole {
  INDIVIDUAL = 'INDIVIDUAL',
  MULTIPLE_INDIVIDUALS = 'MULTIPLE_INDIVIDUALS',
  OFFICER_OF_COMPANY = 'OFFICER_OF_COMPANY',
  EMPLOYEE_OF_COMPANY = 'EMPLOYEE_OF_COMPANY',
}

export enum TrusteeRoleInCompany {
  SOLE_DIRECTOR = 'SOLE_DIRECTOR',
  DIRECTOR = 'DIRECTOR',
}

export enum InviteJoinReason {
  OWNER_INVITE_BUILDER = 'OWNER_INVITE_BUILDER',
  EMPLOYEE_INVITE_PARTNER = 'EMPLOYEE_INVITE_PARTNER',
  EMPLOYEE_INVITE_SOLE_TRADER = 'EMPLOYEE_INVITE_SOLE_TRADER',
  EMPLOYEE_INVITE_DIRECTOR = 'EMPLOYEE_INVITE_DIRECTOR',
  EMPLOYEE_INVITE_TRUST = 'EMPLOYEE_INVITE_TRUST',
}

export enum FailedSignupReason {
  OWNER_SIGN_UP = 'OWNER_SIGN_UP',
  TRUSTEE_COMPANY_EMPLOYEE_SIGN_UP = 'TRUSTEE_COMPANY_EMPLOYEE_SIGN_UP',
  EXISTING_BUSINESS_SIGN_UP = 'EXISTING_BUSINESS_SIGN_UP',
  EMPLOYEE_SIGN_UP = 'EMPLOYEE_SIGN_UP',
}

export enum BackgroundCheckStatus {
  REQUESTED = 'REQUESTED',
  PASS = 'PASS',
  FAIL = 'FAIL',
  RESULT_READY = 'RESULT_READY',
  UNKNOWN = 'UNKNOWN',
}

export enum ClearingState {
  RECEIVED = 'RECEIVED',
  CLEARING = 'CLEARING',
  CLEARED = 'CLEARED',
}

export enum SignupStep {
  WELCOME,
  TELL_US_BUSINESS,
  TELL_US_BUSINESS_AND_ROLE,
  TELL_US_COMPANY_AND_ROLE,
  TELL_US_TRUSTS_AND_ROLE,
  TELL_US_TRUSTS_COMPANY_AND_ROLE,
  MORE_DETAILS,
  CONGRATULATIONS,
  CANT_SIGN,
  CANT_SIGN_AS_OWNER,
  CANT_SIGN_AS_TRUST,
  CANT_SIGN_AS_ONLY_SUPPORT_ONE_PER_BUSINESS,
  CANT_SIGN_AS_EMPLOYEE_FOR_COMPANY,
  CANT_SIGN_AS_EMPLOYEE_FOR_BUSINESS,
  ALREADY_EXIST,
}

export enum NewAccountStep {
  BENEFITS,
  BUSINESS_DETAILS,
  SMART_UI,
  EXTERNAL_IDV_CHECK,
}

export const roleTypesInContract = {
  [RoleTypeInContract.OWNER]: 'Property owner',
  [RoleTypeInContract.BUILDER]: 'Builder',
  [RoleTypeInContract.SUBCONTRACTOR]: 'Subcontractor',
  [RoleTypeInContract.SUPPLIER]: 'Supplier',
  [RoleTypeInContract.SUPERINTENDENT]: 'Superintendent',
  [RoleTypeInContract.CONTRACTOR]: 'Contractor',
};

export const businessStructures = {
  [BusinessStructure.COMPANY]: 'Company',
  [BusinessStructure.SOLE_TRADER]: 'Sole trader',
  [BusinessStructure.PARTNERSHIP]: 'Partnership',
  [BusinessStructure.TRUST]: 'Trust',
  [BusinessStructure.OTHER]: 'Something else',
};

export interface NewAccountFormStepper {
  index: NewAccountStep;
  title: string;
  description?: string;
  included: boolean;
}

export const newAccountStepsForDev = [
  {
    index: NewAccountStep.BENEFITS,
    title: 'Get a Paid transaction account',
    description: 'Benefits',
    included: true,
  },
  {
    index: NewAccountStep.BUSINESS_DETAILS,
    title: 'Get a Paid transaction account',
    description: 'Business details',
    included: true,
  },
];

export const newAccountSteps = [
  {
    index: NewAccountStep.BENEFITS,
    title: 'Get a Paid transaction account',
    description: 'Benefits',
    included: true,
  },
  {
    index: NewAccountStep.BUSINESS_DETAILS,
    title: 'Get a Paid transaction account',
    description: 'Business details',
    included: true,
  },
  {
    index: NewAccountStep.SMART_UI,
    title: 'Get a Paid transaction account',
    description: 'Identity Verification',
    included: true,
  },
  {
    index: NewAccountStep.EXTERNAL_IDV_CHECK,
    title: 'Get a Paid transaction account',
    description: 'Biometrics / ID Scan Validation',
    included: true,
  },
];
