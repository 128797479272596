import { type ClaimMethod } from '@paid-ui/enums/claim';
import {
  ContractSource,
  ContractState,
  ContractTemplate,
  ContractType,
} from '@paid-ui/enums/contract';
import { ProjectRole, ProjectStatus, type ProjectType } from '@paid-ui/enums/project';
import { type ProjectSummary } from '@paid-ui/queries/project-list';
import { type ContractSummary } from '@paid-ui/queries/project-summary';
import type { Address } from '@paid-ui/schemas/zod/address';
import { type DraftContract } from '@paid-ui/types/contracts';
import { type RequiringAction } from '@paid-ui/types/requiring-actions';
import { groupBy } from 'lodash';
import { proxy } from 'valtio';
import { devtools, subscribeKey } from 'valtio/utils';

import { featureManager } from './feature';
import { requiringActionManager } from './requiring-action';
import { userManager } from './user';

const initialState = {
  id: '',
  projectName: '',
  projectNumber: '',
  projectAddress: null as Address | null,
  projectStatus: null as ProjectStatus | null,
  customName: '',
  fullProjectName: '',
  suburbAndState: '',
  timezone: '',
  defaults: {
    claimMethod: '' as ClaimMethod | '',
    projectRole: '' as ProjectRole | '',
    projectType: '' as ProjectType | '',
  },
  defaultContractId: '',

  primeContract: null as ContractSummary | null,
  contractSummaries: [] as ContractSummary[],
  draftContracts: [] as DraftContract[],
  projectContractSet: new Set<string>(),

  requiringActions: [] as RequiringAction[],
  userGroup: {
    id: '',
  },

  permissions: {
    canEditProject: false,
    canDeleteProject: false,
    canArchiveProject: false,
    canCreateContract: false,
    canCreatePrimeContract: false,
    canCreateSuperContract: false,
    canCreateSecondaryContract: false,
  },

  forceUpdate: 0,
  loading: true,
};

export const projectManager = proxy(initialState);

devtools(projectManager, {
  name: 'Current Project',
  enabled: false,
});

const getDefaultContract = (contracts: ContractSummary[] = []) => {
  const searchParams = new URLSearchParams(window.location.search);
  const contractId = searchParams.get('contractId');
  const contract = contracts.find((item) => item.id === contractId);
  if (contract) return contract;

  const {
    HEAD_CONTRACT: headContracts = [],
    SUBCONTRACT: subcontracts = [],
    SUPPLY_CONTRACT: supplyContracts = [],
  } = groupBy(contracts, (contract) => contract.contractType);

  const [headContract] = headContracts;
  if (headContract) return headContract;

  const [subcontract] = subcontracts;
  if (subcontract) return subcontract;

  const [supplyContract] = supplyContracts;
  if (supplyContract) return supplyContract;

  return null;
};

export const resetProject = () => {
  Object.assign(projectManager, initialState);
};

// Use when navigate from other pages
export const saveProjectSummary = (data: ProjectSummary) => {
  const { id } = projectManager;
  if (id === data.id) return;
  const projectName = [data.projectNumber, data.projectName].filter(Boolean).join(' · ');

  Object.assign(projectManager, {
    ...initialState,
    id: data.id,
    projectName,
    projectAddress: data.projectAddress,
    defaultContractId: '',
  });
};

// Use when open URL directly
export const saveProject = (data: Partial<typeof initialState>) => {
  const { enableArchiveProject, enableSuperContract } = featureManager;
  const { displayName, isOwner, provideBuildingServices, disableContractActions } = userManager;
  const { projectActions } = requiringActionManager;

  const {
    projectAddress,
    projectStatus,
    customName = '',
    projectName: shortProjectName,
    projectNumber = '',
    contractSummaries = [],
    draftContracts = [],
    userGroup,
    defaults,
  } = data ?? {};

  const { suburb, state } = projectAddress || {};
  const suburbAndState = [suburb, state].filter(Boolean).join(' ');
  const projectName = [projectNumber, shortProjectName].filter(Boolean).join(' · ');
  const fullProjectName = [projectName, suburbAndState].filter(Boolean).join(' · ');
  const projectRole = defaults?.projectRole ?? ProjectRole.PRINCIPAL;

  const draftSummaries = draftContracts.map((draft) => {
    const { contractType, contract } = draft;
    return {
      id: contract.id,
      name: contract.name,
      contractType,
      contractTemplate: contract.contractTemplate,
      contractState: contract.contractState,
      source:
        contract.contractTemplate === ContractTemplate.OFF_PLATFORM
          ? ContractSource.OFF_PLATFORM
          : ContractSource.ON_PLATFORM,
      contractPrice: contract.price?.current ?? 0,
      partyName: displayName,
      theOtherPartyName: contract.theOtherPartyName?.trim() ?? '',
      contract,
      isDraftContract: true,
    } as ContractSummary;
  });

  const allContractSummaries = [...contractSummaries, ...draftSummaries];
  const defaultContract = getDefaultContract(allContractSummaries);
  const primeContract =
    allContractSummaries.find(
      (contract) =>
        contract.contractType === ContractType.HEAD_CONTRACT &&
        contract.contractState !== ContractState.ABANDONED,
    ) ?? null;

  const canArchiveProject = !disableContractActions && enableArchiveProject;
  const canEditProject = !disableContractActions && projectStatus !== ProjectStatus.ARCHIVED;
  const canDeleteProject = !disableContractActions && allContractSummaries.length === 0;
  const canCreateContract =
    !disableContractActions &&
    (enableSuperContract
      ? projectStatus !== ProjectStatus.ARCHIVED
      : provideBuildingServices && projectStatus !== ProjectStatus.ARCHIVED);

  const permissions = {
    canArchiveProject,
    canEditProject,
    canDeleteProject,
    canCreateContract,
    canCreatePrimeContract: canCreateContract && !primeContract,
    canCreateSecondaryContract:
      canCreateContract &&
      !isOwner &&
      ![ProjectRole.PRINCIPAL, ProjectRole.SUPERINTENDENT].includes(projectRole),
  };

  Object.assign(projectManager, {
    ...data,
    customName,
    projectNumber,
    projectName,
    projectStatus,
    suburbAndState,
    fullProjectName,
    contractSummaries: allContractSummaries,
    draftContracts,
    primeContract,
    userGroup,
    projectContractSet: new Set(allContractSummaries.map((contract) => contract.id)),
    defaultContractId: defaultContract?.id ?? '',
    permissions,
    requiringActions: data.id ? (projectActions[data.id] ?? []) : [],
    loading: false,
    defaults: {
      ...data.defaults,
    },
  });
};

export const reloadProject = () => {
  const { id, forceUpdate } = projectManager;
  if (!id) return;
  projectManager.loading = true;
  projectManager.forceUpdate = forceUpdate > 100 ? 1 : forceUpdate + 1;
};

subscribeKey(featureManager, 'enableArchiveProject', (value) => {
  projectManager.permissions.canArchiveProject = Boolean(value);
});

subscribeKey(requiringActionManager, 'projectActions', (value) => {
  const { id } = projectManager;
  if (!id) return;
  projectManager.requiringActions = value[id] ?? [];
});
