export enum UserConsentId {
  SERVICE_AGREEMENT = 'ServiceAgreement',
  PRIVACY_POLICY = 'PrivacyPolicy',
}

export enum UserConsentType {
  PAID_SERVICE_AGREEMENT = 'PAID_SERVICE_AGREEMENT',
  SHAYPE_TERMS_AND_CONDITIONS = 'SHAYPE_TERMS_AND_CONDITIONS',
  SHAYPE_PRODUCT_DISCLOSURE_STATEMENT = 'SHAYPE_PRODUCT_DISCLOSURE_STATEMENT',
  SHAYPE_FINANCIAL_SERVICES_GUIDE = 'SHAYPE_FINANCIAL_SERVICES_GUIDE',
  SHAYPE_TARGET_MARKET_DETERMINATION = 'SHAYPE_TARGET_MARKET_DETERMINATION',
}

export const consentIdTypeMap: Record<UserConsentId, UserConsentType> = {
  [UserConsentId.SERVICE_AGREEMENT]: UserConsentType.PAID_SERVICE_AGREEMENT,
  [UserConsentId.PRIVACY_POLICY]: UserConsentType.PAID_SERVICE_AGREEMENT,
};

export const consentIdTitleMap: Record<UserConsentId, string> = {
  [UserConsentId.SERVICE_AGREEMENT]: 'Paid Services Agreement',
  [UserConsentId.PRIVACY_POLICY]: 'Privacy and Cookies Policy.',
};
