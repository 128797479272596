import { envsafe, num, str, url, type ValidatorSpec } from 'envsafe';

type DeployEnv = 'local' | 'dev' | 'sit' | 'uat' | 'prod';

/** Environments for browser */
export const browserEnvs = envsafe(
  {
    title: str({
      default: 'Paid',
      input: process.env.NEXT_PUBLIC_TITLE,
      docs: 'Title of app',
    }),

    description: str({
      default: 'Financial security. Built for construction.',
      input: process.env.NEXT_PUBLIC_DESCRIPTION,
      docs: 'Description of app',
    }),

    languageCode: str({
      default: 'en-AU',
      input: process.env.NEXT_PUBLIC_LANGUAGE_CODE,
      choices: ['en-AU'],
      docs: 'Default language code of app',
    }),

    colorMode: str({
      default: 'light',
      input: process.env.NEXT_PUBLIC_COLOR_MODE,
      choices: ['light', 'dark'],
      docs: 'Default color mode of app',
    }),

    themeColor: str({
      default: '#ffffff',
      input: process.env.NEXT_PUBLIC_THEME_COLOR,
      docs: 'Theme color of app',
    }),

    googleAnalyticsId: str({
      allowEmpty: true,
      default: '',
      input: process.env.NEXT_PUBLIC_ANALYTICS_ID,
      docs: 'Google Analytics track ID',
    }),

    apiBaseUrl: url({
      input: process.env.NEXT_PUBLIC_API_BASE_URL,
      docs: 'Base API url',
    }),

    apiBaseV2Url: url({
      input: process.env.NEXT_PUBLIC_API_V2_BASE_URL,
      docs: 'Base API url v2',
    }),

    publicConfigUrl: url({
      allowEmpty: true,
      default: '',
      input: process.env.NEXT_PUBLIC_CONFIG_BASE_URL || process.env.NEXT_PUBLIC_API_BASE_URL,
      docs: 'Config API url',
    }),

    ppaCdnUrl: url({
      input: process.env.NEXT_PUBLIC_PPA_CDN_URL,
      docs: 'Contract attachments CDN url',
    }),

    ppcCdnUrl: url({
      input: process.env.NEXT_PUBLIC_PPC_CDN_URL,
      docs: 'PDF contracts CDN url',
    }),

    pceCdnUrl: url({
      input: process.env.NEXT_PUBLIC_PCE_CDN_URL,
      docs: 'Claim evidences CDN url',
    }),

    ppaBucketName: str({
      input: process.env.NEXT_PUBLIC_PPA_BUCKET_NAME,
      docs: 'Contract attachments bucket name',
    }),

    ppcBucketName: str({
      input: process.env.NEXT_PUBLIC_PPC_BUCKET_NAME,
      docs: 'Signed PDF contracts bucket name',
    }),

    pceBucketName: str({
      input: process.env.NEXT_PUBLIC_PCE_BUCKET_NAME,
      docs: 'Claim evidences bucket name',
    }),

    googleMapsApiKey: str({
      input: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      docs: 'Google Maps API key',
    }),

    mockApiBaseUrl: url({
      input: process.env.NEXT_PUBLIC_MOCK_API_BASE_URL,
      docs: 'Mock base API url',
    }),

    maxFileSize: num({
      default: 45_000_000,
      input: process.env.NEXT_PUBLIC_MAX_FILE_SIZE,
      docs: 'Default max file size',
    }),

    env: str({
      input: process.env.NEXT_PUBLIC_ENV,
      docs: 'Deployed env',
      choices: ['local', 'dev', 'uat', 'sit', 'prod'],
    }) as ValidatorSpec<DeployEnv>,

    blacklistGroupIds: str({
      allowEmpty: true,
      default: '',
      input: process.env.NEXT_PUBLIC_BLACKLIST_GROUP_IDS,
      docs: 'Blacklist group ids, split with comma',
    }),
    blacklistUserIds: str({
      allowEmpty: true,
      default: '',
      input: process.env.NEXT_PUBLIC_BLACKLIST_USER_IDS,
      docs: 'Blacklist user ids, split with comma',
    }),
  },
  {
    strict: true,
  },
);
